import { useMemo } from 'react';
import {
  getProductList,
  getProductZISListWrapper,
  getProductListDatajet,
  getProductZISListWrapperFromDatajet,
} from 'api/catalog';
import { PageInfo, isZISPageInfo } from 'utils/catalog/catalog-page-type';
import useCatalogSource from './useCatalogSource';
import { useDatajet } from './useDatajet';

const useCatalogFetcher = (pageInfo: PageInfo) => {
  const source = useCatalogSource(pageInfo);
  const { abTestParam, isEnabled: isDatajetEnabled } = useDatajet();

  return useMemo(() => {
    if (!source) {
      return null;
    }

    switch (source) {
      case 'datajet': {
        return getProductListDatajet(abTestParam);
      }

      case 'zis': {
        const { SellerId = '' } = isZISPageInfo(pageInfo) ? pageInfo.storeInfo : {};

        if (isDatajetEnabled) {
          return getProductZISListWrapperFromDatajet(SellerId);
        }

        return getProductZISListWrapper(SellerId);
      }

      default: {
        return getProductList;
      }
    }
  }, [source, abTestParam, pageInfo, isDatajetEnabled]);
};

export default useCatalogFetcher;
