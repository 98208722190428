import {
  OptimizelyDecideOption,
  useDecision,
  OptimizelyContext,
  ReactSDKClient,
} from '@optimizely/react-sdk';
import { useContext, useEffect, useMemo } from 'react';
import { DEFAULT_ENABLE_VARIATION } from 'constants/optimizely';
import Cookie from 'utils/cookie';
import { useInvokeCallbackOnceWhen } from './useInvokeCallbackOnceWhen';

export const useOptimizelyDecision = (
  key: string,
  enableVariationValue = DEFAULT_ENABLE_VARIATION,
  shouldSendDecision = true,
) => {
  const { optimizely } = useContext(OptimizelyContext);
  const [decision] = useDecision(key, {
    decideOptions: [OptimizelyDecideOption.DISABLE_DECISION_EVENT],
  });

  useInvokeCallbackOnceWhen(() => {
    if (shouldSendDecision) {
      callDecisionMethod(optimizely, key);
    }
  }, !!optimizely);

  const variationKey = decision.variationKey;
  const isEnabled = decision.enabled;

  return isEnabled && variationKey === enableVariationValue;
};

export const callDecisionMethod = (optimizely: ReactSDKClient | null, key: string) => {
  optimizely?.onReady().then(() => {
    const cookie = new Cookie();
    const hasSentDecisionMethod = cookie.get(key);

    if (hasSentDecisionMethod) {
      return;
    }

    optimizely.decide(key);
    const currentTime = new Date();

    currentTime.setDate(currentTime.getDate() + 1);
    cookie.set(key, '1', currentTime.toUTCString());
  });
};

export const useSendOptimizelyDecisionForKeys = (keys: string[]) => {
  const { optimizely } = useContext(OptimizelyContext);

  const isClientReady = optimizely?.isReady();

  const decisions = useMemo(
    () => ({
      decisions:
        isClientReady && optimizely
          ? optimizely.decideForKeys(keys, [OptimizelyDecideOption.DISABLE_DECISION_EVENT])
          : {},
    }),
    [isClientReady, keys, optimizely],
  );

  useEffect(() => {
    keys.forEach((key) => {
      callDecisionMethod(optimizely, key);
    });
  }, [keys, optimizely]);

  return decisions;
};
