import { useRouter } from 'next/router';
import { useBobFeatureFlags } from 'hooks/api/useBobFeatureFlags';
import { getUserAgent, isSearchCrawler } from 'utils/bots';
import { PageInfo, isPIPPageInfo, isZISPageInfo } from 'utils/catalog/catalog-page-type';
import { useDatajet } from './useDatajet';

/**
 * There are 4 types of catalog source currently
 *   * `datajet`: DataJet, personalization provided by GFG
 *   * `solr`: Zalora in-house personalization
 *   * `zis`: Zalora Interactive Store
 *
 * Based on feature flags from BOB and optimizely, we will decide which source to use
 */
const useCatalogSource = (pageInfo: PageInfo) => {
  const { query } = useRouter();
  const { data } = useBobFeatureFlags();
  const { isEnabled: isDatajetEnabled } = useDatajet();

  // It is for debug purpose only
  // - sometime we want to compare the result between solr and datajet
  // - for troubleshooting
  if (typeof query.forceInhouse === 'string' && query.forceInhouse.toLocaleLowerCase() === 'true') {
    return 'solr';
  }

  if (isZISPageInfo(pageInfo)) {
    return 'zis';
  }

  if (isPIPPageInfo(pageInfo)) {
    return 'solr';
  }

  const userAgent = getUserAgent();

  if (isSearchCrawler(userAgent)) {
    // https://zalora.atlassian.net/wiki/spaces/FE/pages/3729719348/Dynamic+rendering+for+Catalog+pages
    return 'solr';
  }

  if (query.hasOwnProperty('getSKU')) {
    return 'solr';
  }

  // feature flag for catalog source is fetching on server, don't need to wait for it.
  if (data === undefined) {
    return; // don't know source until we got the value of feature flag
  }

  if (isDatajetEnabled) {
    return 'datajet';
  }

  return 'solr';
};

export default useCatalogSource;
